import { IReportingQuery, ReportingDP } from '@/types/Reporting';
import { mapValues, last } from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { baseApi } from './base';

export const dayComparator = (a: ReportingDP, b: ReportingDP) => {
  const dayA = dayjs(a.time);
  const dayB = dayjs(b.time);
  return dayA < dayB ? -1 : dayA > dayB ? 1 : 0;
};

const REPORTING_URL = import.meta.env.VITE_REPORTING_API ?? '/api/v3/reporting';

type ReportingV4Request = {
  measurements: string[];
  items: string[];
  start?: string;
  end?: string;
  interval?: string;
};

type ReportingV4Response = {
  data: {
    [key: string]: { start: string; end: string; value: number }[];
  };
};

export const reportingApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // The response type really varies depending on the query, especially if you include group_by with
    // different numbers of fields. For now, we just use any and cast to the correct type.
    getReporting: builder.query<any, IReportingQuery>({
      providesTags: ['Reporting'],
      transformResponse: (response: { data: ReportingDP[] | ReportingDP }, meta, arg) =>
        arg.group_by
          ? response.data
          : Array.isArray(response.data)
          ? response.data.sort(dayComparator)
          : [response.data],
      query: (body) => ({ url: REPORTING_URL, method: 'POST', body }),
    }),

    getV4Reporting: builder.query<ReportingV4Response, ReportingV4Request>({
      providesTags: ['Reporting'],
      query: (body) => ({ url: REPORTING_URL.replace('v3', 'v4'), method: 'POST', body }),
    }),

    get24hTrend: builder.query<
      Record<string, ReportingDP[]>,
      { type: 'room' | 'zone'; ids: string[] }
    >({
      providesTags: ['Reporting'],
      transformResponse: (response: { data: Record<string, ReportingDP[] | ReportingDP> }) =>
        mapValues(response.data, (v) => (Array.isArray(v) ? v.sort(dayComparator) : [v])),
      query: ({ type, ids = [] }) => ({
        url: REPORTING_URL,
        method: 'POST',
        body: {
          group_by: {
            order: type === 'room' ? ['room_id'] : ['zone_id'],
            raw: true,
          },
          window: {
            every: '1h',
            function: 'mean',
          },
          options: {
            format: 'json',
          },
          filter: {
            start: '-24h',
            measurements: ['traffic', type === 'room' ? 'room_occupancy' : 'zone_occupancy'],
            [type === 'room' ? 'rooms' : 'zones']: {
              eq: ids,
            },
            value: {
              gte: 0,
            },
          },
        },
      }),
    }),

    getLatestGrouped: builder.query<
      Record<string, ReportingDP | null>,
      { type: 'room' | 'zone'; ids: string[]; currentTime: Dayjs }
    >({
      providesTags: ['Reporting'],
      transformResponse: (response: { data: Record<string, ReportingDP[] | ReportingDP> }) =>
        mapValues(response.data, (v) =>
          Array.isArray(v) ? last(v.sort(dayComparator)) ?? null : v,
        ),
      query: ({ type, ids = [], currentTime }) => ({
        url: REPORTING_URL,
        method: 'POST',
        body: {
          group_by: {
            order: [type === 'room' ? 'room_id' : 'zone_id'],
            raw: true,
          },
          window: {
            every: '1m',
            function: 'max',
          },
          filter: {
            start: currentTime.utc().subtract(5, 'm').startOf('m').toISOString(),
            // Add 1 minute delay so that data is more likely to be available
            end: currentTime.utc().subtract(1, 'm').startOf('m').toISOString(),
            measurements: [type === 'room' ? 'room_occupancy' : 'zone_occupancy'],
            [type === 'room' ? 'rooms' : 'zones']: {
              eq: ids,
            },
            value: {
              gte: 0,
            },
          },
        },
      }),
    }),

    getLatest: builder.query<
      ReportingDP | null,
      { type: 'room' | 'zone' | 'floor'; id: string; currentTime: Dayjs }
    >({
      providesTags: ['Reporting'],
      transformResponse: (response: { data: ReportingDP[] | ReportingDP }) =>
        Array.isArray(response.data)
          ? last(response.data.sort(dayComparator)) ?? null
          : response.data,
      query: ({ type, id, currentTime }) => ({
        url: REPORTING_URL,
        method: 'POST',
        body: {
          group_by: {
            order: [],
            raw: true,
          },
          window: {
            every: '1m',
            function: 'max',
          },
          filter: {
            start: currentTime.utc().subtract(5, 'm').startOf('m').toISOString(),
            // Add 1 minute delay so that data is more likely to be available
            end: currentTime.utc().subtract(1, 'm').startOf('m').toISOString(),
            measurements: [
              type === 'room'
                ? 'room_occupancy'
                : type === 'zone'
                ? 'zone_occupancy'
                : 'floor_occupancy',
            ],
            [type === 'room' ? 'rooms' : type === 'zone' ? 'zones' : 'spaces']: {
              eq: [id],
            },
            value: {
              gte: 0,
            },
          },
        },
      }),
    }),
  }),
});

export const {
  useGetReportingQuery,
  useGetV4ReportingQuery,
  useGet24hTrendQuery,
  useGetLatestGroupedQuery,
  useGetLatestQuery,
} = reportingApi;
