import React from 'react';
import { Tabs } from 'antd';
import { useHistory } from 'react-router-dom';

const { TabPane } = Tabs;

const SettingsTabs: React.FC = () => {
  const history = useHistory();

  const handleTabClick = (key: string) => {
    history.push(`/settings/${key}`);
  };

  return (
    <div className="flex justify-end w-full">
      <Tabs
        defaultActiveKey="tags"
        tabPosition="left"
        className="w-1/4"
        onTabClick={handleTabClick}
      >
        <TabPane tab="Tags" key="tags" />
      </Tabs>
    </div>
  );
};

export default SettingsTabs;
