import { useCallback, useEffect, useState } from 'react';
import i18n from 'i18next';

/**
 * Retrieves user preferences and allows temporary updates to them that can then be saved.
 */
export function useUserPreferences() {
  const [tempPreferences, setTempPreferences] = useState<{ language: string } | null>(null);

  //only default language is supported at the moment
  const preferences = {
    language: 'en-US',
  };

  useEffect(() => {
    const targetLanguage = tempPreferences ? tempPreferences.language : preferences?.language;
    targetLanguage && i18n.changeLanguage(targetLanguage);
  }, [tempPreferences, preferences]);

  // reintroduce user preference settings in the future
  // const saveUserPreferences = useCallback(() => {
  //   if (tempPreferences) {
  //     postUserPreferences({ ...preferences, ...tempPreferences });
  //   }
  // }, [postUserPreferences, preferences, tempPreferences]);

  const clearTempPreferences = useCallback(() => {
    preferences && i18n.changeLanguage(preferences.language);
    setTempPreferences(null);
  }, [preferences]);

  return {
    preferences: { ...preferences, ...tempPreferences },
    setTempPreferences,
    clearTempPreferences,
    // saveUserPreferences,
  };
}
