import { Select } from 'antd';

interface TagSelectProps {
  tags: Set<string>;
  options: string[];
  onSelect: (item: string) => void;
  onDeselect?: (item: string) => void;
  optionToLabel?: (option: string) => string;
  placeholder?: string;
}

const TagSelect: React.FC<TagSelectProps> = ({
  tags,
  options,
  onSelect,
  onDeselect,
  optionToLabel,
  placeholder = 'Add a Tag',
}) => {
  const selectOptions = options.map((option) => ({
    value: option,
    label: optionToLabel ? optionToLabel(option) : option,
  }));

  return (
    <Select
      mode="tags"
      className="w-72 min-h-8 rounded-md"
      placeholder={placeholder}
      onSelect={onSelect}
      onDeselect={onDeselect}
      options={selectOptions}
      filterOption={(input, option) =>
        !!(option && option.label.toLowerCase().includes(input.toLowerCase()))
      }
      value={Array.from(tags)}
      notFoundContent="No tags found"
      data-id="tag-select"
    />
  );
};

export default TagSelect;
