import { UserMessageComponent } from '@/components/UserMessage/index';
import { RoleDefinition, UserContext } from '@/contexts';
import { useAppDispatch } from '@/redux/hooks';
import { closeDialog, setDialog } from '@/redux/reducers/dialog';
import { IDecodedAccessToken } from '@/types';
import { User, useAuth0 } from '@auth0/auth0-react';
import i18n from 'i18next';
import jwt_decode from 'jwt-decode';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useInterval } from './useInterval';

/**
 * Hooks for proceeding process after initial auth. **MUST ONLY BE CALLED ONCE!**
 * @returns status of auth&token
 */
export const useUser = (): {
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: User;
} => {
  const history = useHistory();
  const {
    isAuthenticated,
    isLoading,
    error: authError,
    loginWithRedirect,
    getAccessTokenSilently,
    user,
  } = useAuth0();
  const dispatch = useAppDispatch();
  const { setValue: setUserContextValue } = useContext(UserContext);

  const preferences = {
    language: 'en-US',
  };
  const supportedLanguages = {
    default: 'en-US',
    languages: {
      'en-US': 'English (United States)',
      'zh-Hans': 'Chinese (Simplified)',
    },
  };

  const initialLoadingRef = useRef(true);

  // Action for getting access token
  const checkTokenUpdate = useCallback(async () => {
    if (isLoading) return;

    if (isAuthenticated) {
      const token = await getAccessTokenSilently();

      // Initially fetch user, and determine superuser status
      if (initialLoadingRef.current) {
        const decoded: IDecodedAccessToken = jwt_decode(token);
        const roles = decoded['http://butlr.io/roles'] as RoleDefinition[];
        const isSuperUser = roles.includes('Superuser');
        const clientId = decoded['http://butlr.io/client_id'];

        //check if client id has changed unintentionally
        const oldToken = window.sessionStorage.getItem('client_id');
        const newToken = clientId;
        if (newToken && oldToken && newToken !== oldToken) {
          dispatch(
            setDialog({
              content: (
                <UserMessageComponent
                  message="You have multiple tabs open with different clients, and are being redirected to the most recent."
                  onClick={() => dispatch(closeDialog())}
                />
              ),
            }),
          );
          window.sessionStorage.setItem('client_id', clientId);
          history.push('/');
        }

        setUserContextValue({ roles, isSuperUser, clientId });
        initialLoadingRef.current = false;
      }
    } else {
      getAccessTokenSilently()
        .then(() => {
          window.location.reload();
        })
        .catch(() => {
          loginWithRedirect({
            appState: { returnTo: window.location.pathname },
          });
        });
    }
  }, [
    dispatch,
    getAccessTokenSilently,
    history,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    setUserContextValue,
  ]);

  // Polling for refreshing access token or redirect to login
  useInterval(5000, checkTokenUpdate);

  // Error handling, and redirect to first space if no space is indicated by query param
  useEffect(() => {
    if (authError) {
      // Error while auth
      console.error(authError.message);
    } else if (!preferences) {
      // Fetched no user data
      console.error('Error while fetching user');
    }
  }, [authError, preferences]);

  // Change preference settings with fetched preference
  useEffect(() => {
    if (!supportedLanguages) return;

    const { default: defaultLanguage } = supportedLanguages;

    // Change language
    i18n.changeLanguage(defaultLanguage);
  }, []);

  return {
    isAuthenticated,
    isLoading: isLoading,
    user,
  };
};
