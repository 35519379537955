import React from 'react';

interface SettingsHeaderProps {
  headerText: string;
  subText: string;
}

const SettingsHeader: React.FC<SettingsHeaderProps> = ({ headerText, subText }) => {
  return (
    <div className="h-[142px] bg-white border-b border-gray-300 flex flex-col justify-center px-5">
      <h1 className="text-2xl mb-4">{headerText}</h1>
      <p className="text-gray-400">{subText}</p>
    </div>
  );
};

export default SettingsHeader;
