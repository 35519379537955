import { memo, useEffect, useState } from 'react';
import { useSRZId } from '@/hooks';
import { Mainboard } from '@/pages/HomePage/OrganizationPage/Mainboard';
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { sortBy } from 'lodash';
import { PopupContextProvider } from '@/contexts';
import { PageLayoutMain, PageLayoutRoot } from '@/components/PageLayout';
import { selectDirty } from '@/components/studio/ScopingEditor/reducer';
import { gql } from '@apollo/client';
import { useOrganizationPageFloorsLazyQuery } from '@/.generated/graphql';
import { useSelector } from 'react-redux';
import DashboardHeader from '@/pages/DashboardHeader';
import { datadogRum } from '@datadog/browser-rum';
import { SettingsPage } from '@/pages/Settings';

export const OrganizationPage = () => {
  const { spaceId, roomId, zoneId } = useSRZId();
  const history = useHistory();
  const location = useLocation();
  const [getFloors] = useOrganizationPageFloorsLazyQuery();

  const isDirty = useSelector(selectDirty);

  useEffect(() => {
    const onbeforeunload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = '';
    };

    if (isDirty) {
      window.addEventListener('beforeunload', onbeforeunload);
    }

    return () => window.removeEventListener('beforeunload', onbeforeunload);
  }, [isDirty]);

  // Error handling, and redirect to first space if no space is indicated by query param
  useEffect(() => {
    const isSettingsRoute = location.pathname.startsWith('/settings');
    if (!spaceId && !isSettingsRoute) {
      // Space id is not defined in url, redirect to first floor if on the right page
      getFloors().then((res) => {
        const floors = res.data?.floors?.data ?? [];
        const floor = sortBy(floors, 'name')[0]?.id;
        if (floor) {
          history.push({ search: `?spaceId=${floor}` });
        }
      });
    }
  }, [spaceId, history, getFloors, location.pathname]);

  const [detailPanelOpen, setToggleDetailsPanel] = useState<boolean>(false);

  return (
    <PopupContextProvider>
      <DashboardHeader />
      <PageLayoutRoot>
        <Sidebar />
        <Switch>
          <Route path={['/settings', '/settings/*']}>
            <SettingsPage />
          </Route>
          <PageLayoutMain>
            <Header
              onDetailsPanelToggle={() => {
                datadogRum.addAction('Toggle Details Panel/Settings', {
                  source: 'settings-and-details',
                });
                setToggleDetailsPanel((open) => !open);
              }}
            />
            {(spaceId || roomId || zoneId) && (
              <Mainboard
                isRoom={!!roomId}
                isZone={!!zoneId}
                detailsPanelOpen={detailPanelOpen}
                onDetailsPanelToggle={setToggleDetailsPanel}
              />
            )}
          </PageLayoutMain>
        </Switch>
      </PageLayoutRoot>
    </PopupContextProvider>
  );
};

OrganizationPage.queries = {
  Floors: gql`
    query OrganizationPageFloors {
      floors {
        data {
          id: floor_id
          name
        }
      }
    }
  `,
};

export default memo(OrganizationPage);
