import { PageLayoutSidebar } from '../PageLayout';
import { useState } from 'react';
import { MenuFoldOutIcon } from '@/components/Icons';
import { CreateSpaceButton } from '@/pages/HomePage/OrganizationPage/Sidebar/CreateSpaceButton';
import SidebarMenu from './SidebarMenu';
import { Link, useRouteMatch } from 'react-router-dom';
import { Icon } from '../primitives/icon/Icon';
import AccountSettingsTabs from './AccountSettingsTabs';
const SIDEBAR_WIDTH_COLLAPSED = 'w-[56px]';
const SIDEBAR_WIDTH_EXPANDED = 'w-[300px]';

export function Sidebar() {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const sidebarClasses = sidebarOpen
    ? `transform transition-transform duration-300 ${SIDEBAR_WIDTH_EXPANDED}`
    : `transform transition-transform duration-300 ${SIDEBAR_WIDTH_COLLAPSED}`;

  const isSettingsRoute = useRouteMatch({ path: ['/settings', '/settings/*'] });

  return (
    <PageLayoutSidebar className="text-xs text-gray-800 pt-6 flex flex-col border-r border-gray-200">
      <div className={sidebarClasses}>
        {isSettingsRoute ? (
          <>
            <Link to="/" className="btn btn-primary flex ml-4 text-lg font-light">
              {
                <>
                  <Icon name="left" className="mr-3 mt-1"></Icon> <span className="">Back</span>
                </>
              }
            </Link>
            <div className="flex mb-8 ml-6 my-8 text-gray-900 text-sm">
              <Icon name="gear" className="h-4 w-4 mt-[2px]" />
              <span className="ml-2">Account Settings</span>
            </div>
            <div className="mt-8 pr-1 bg-white flex">
              <AccountSettingsTabs />
            </div>
          </>
        ) : sidebarOpen ? (
          <SidebarMenu onSidebarOpenClose={setSidebarOpen} sidebarOpen={sidebarOpen} />
        ) : (
          <div className={`flex flex-col justify-between h-[90vh] ${SIDEBAR_WIDTH_COLLAPSED}`}>
            <div className="flex items-center justify-center pt-4">
              <MenuFoldOutIcon
                className="w-4 h-4 text-product-gray900 cursor-pointer mt-[1px]"
                onClick={() => setSidebarOpen(true)}
              />
            </div>
            <div></div>
            <div className={`flex flex-col items-center justify-center ${SIDEBAR_WIDTH_COLLAPSED}`}>
              <div className="w-[32px] bg-gray-200 h-px mb-4 mx-4"></div>
              <CreateSpaceButton data-id="create-new-floor" variant="compact" />
            </div>
          </div>
        )}
      </div>
    </PageLayoutSidebar>
  );
}
