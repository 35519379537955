import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SettingsHeader from './SettingsHeader';
import { TagManagement } from './TagManagement';

export const SettingsPage = () => {
  return (
    <div className="bg-gray-50 w-full">
      {/* we can make the input text dynamic when there are other funcitons to display on the settings page, beyond just tags */}
      <SettingsHeader headerText="Tags Management" subText="Manage your preferences and settings" />
      <Switch>
        <Route exact path={[`/settings`, `/settings/*`]}>
          <TagManagement />
        </Route>
      </Switch>
    </div>
  );
};

export default SettingsPage;
