import { Button } from '@/components/primitives';
import { Icon } from '../primitives/icon/Icon';

interface ConfirmationProps {
  text: string;
  subtext?: string;
  onConfirm: () => void;
  onClose: () => void;
}

export const Confirmation = ({ onConfirm, onClose, text, subtext }: ConfirmationProps) => {
  return (
    <div>
      <div className="flex items-start justify-between text-xl py-3">
        <div className="mr-3">{text}</div>
        <button onClick={onClose} className="mt-2">
          <Icon name="close"></Icon>
        </button>
      </div>
      <div className="text-sm">{subtext}</div>

      <div className="flex justify-end pt-5">
        <Button variant="ghost" onClick={onClose} className="mr-3">
          Cancel
        </Button>
        <Button variant="solid" color="red" onClick={onConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  );
};
