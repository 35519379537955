import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useGetTagsQuery } from '@/.generated/graphql';
import { CREATE_TAG_MUTATION, GET_TAGS_QUERY } from '@/components/Tags/TagContainer';
import { Space, Table, Input, Button, Form } from 'antd';
import toast from 'react-hot-toast';
import { t } from 'i18next';
import { useAppDispatch } from '@/redux/hooks';
import { closeDialog, setDialog } from '@/redux/reducers/dialog';
import { Confirmation } from '@/components/Confirmation';

export const DELETE_TAG_MUTATION = gql`
  mutation DeleteTag($ids: [String!]!) {
    deleteTags(ids: $ids)
  }
`;
export const UPDATE_TAG_MUTATION = gql`
  mutation UpdateTags($input: [UpdateTagInput!]!) {
    updateTags(input: $input) {
      id
      name
    }
  }
`;
export const TagManagement = () => {
  const { data: availableTags, loading: tagsLoading } = useGetTagsQuery();
  const [deleteTagMutation] = useMutation(DELETE_TAG_MUTATION, {
    refetchQueries: [{ query: GET_TAGS_QUERY }],
  });
  const [createTag] = useMutation(CREATE_TAG_MUTATION, {
    refetchQueries: [{ query: GET_TAGS_QUERY }],
  });
  const [updateTag] = useMutation(UPDATE_TAG_MUTATION, {
    refetchQueries: [{ query: GET_TAGS_QUERY }],
  });
  const dispatch = useAppDispatch();
  const [newTagName, setNewTagName] = useState('');
  const [editingTagId, setEditingTagId] = useState<string | null>(null);
  const [editingTagName, setEditingTagName] = useState<string>('');

  const handleConfirmDeleteTag = async (tagId: any) => {
    dispatch(
      setDialog({
        options: { className: 'rounded-[15px] w-[400px]' },
        content: (
          <Confirmation
            text={'Are you sure you want to delete this tag?'}
            onConfirm={() => {
              handleDeleteTag(tagId);
              dispatch(closeDialog());
            }}
            onClose={() => dispatch(closeDialog())}
          />
        ),
        fixedWidth: 400,
      }),
    );
  };
  const handleDeleteTag = async (tagId: any) => {
    try {
      await deleteTagMutation({ variables: { ids: [tagId] } });
      toast.success('Tag deleted successfully', { duration: 5000 });
    } catch (err) {
      toast.error('Error deleting tag', { duration: 5000 });
    }
  };

  const handleUpdateTag = async (tagId: string, newName: string) => {
    const existingTag = availableTags?.tags?.find((tag: any) => tag.id === tagId);
    if (existingTag && existingTag.name === newName) {
      setEditingTagId(null);
      return;
    }
    try {
      await updateTag({ variables: { input: [{ id: tagId, name: newName }] } });
      toast.success('Tag updated successfully', { duration: 5000 });
      setEditingTagId(null);
    } catch (err) {
      toast.error('Error updating tag', { duration: 5000 });
    }
  };

  const handleCreateTag = async () => {
    try {
      await createTag({ variables: { input: { name: newTagName } } });
      toast.success('Tag created successfully', { duration: 5000 });
      setNewTagName('');
      form.resetFields();
    } catch (err) {
      toast.error('Error creating tag', { duration: 5000 });
    }
  };
  const columns = [
    {
      title: 'Tag Name',
      dataIndex: 'name',
      key: 'name',
      width: 500,
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      render: (text: string, record: any) => {
        if (editingTagId === record.id) {
          return (
            <Input
              value={editingTagName}
              onChange={(e) => setEditingTagName(e.target.value)}
              onBlur={() => handleUpdateTag(record.id, editingTagName)}
            />
          );
        }
        return text;
      },
    },
    {
      title: 'Association',
      dataIndex: 'association',
      key: 'association',

      sorter: (a: any, b: any) => a.association.localeCompare(b.association),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        <>
          <button
            onClick={() => {
              setEditingTagId(record.id);
              setEditingTagName(record.name);
            }}
            className="text-blue-500 mr-2"
          >
            Edit
          </button>
          <button
            onClick={() =>
              record.hasAssociation ? handleConfirmDeleteTag(record.id) : handleDeleteTag(record.id)
            }
            className="text-blue-500"
          >
            Delete
          </button>
        </>
      ),
    },
  ];

  const dataSource = availableTags?.tags?.map((tag: any) => {
    const floorCount = tag?.floors?.length || 0;
    const roomCount = tag?.rooms?.length || 0;
    const zoneCount = tag?.zones?.length || 0;

    return {
      key: tag.id,
      id: tag.id,
      name: tag.name,
      association: `${floorCount} ${t('floor', { count: floorCount })} | ${roomCount} ${t('room', {
        count: roomCount,
      })} | ${zoneCount} ${t('zone', { count: zoneCount })}`,
      hasAssociation: floorCount + roomCount + zoneCount > 0,
    };
  });
  const [form] = Form.useForm();
  return (
    <div className="bg-gray-50 w-full">
      <div className="pt-6 px-6 pb-2 m-6 bg-white rounded">
        <Form form={form} onFinish={handleCreateTag}>
          <Space.Compact className="w-1/2">
            <Form.Item
              className="w-full"
              name="tagName"
              rules={[
                { required: true, message: 'Please enter a tag name' },
                {
                  validator: (_, value) =>
                    availableTags?.tags?.some((tag: any) => tag.name === value)
                      ? Promise.reject(new Error('Tag already exists'))
                      : Promise.resolve(),
                },
              ]}
            >
              <Input
                value={newTagName}
                onChange={(e) => setNewTagName(e.target.value)}
                placeholder="Enter new tag name"
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="bg-black text-white hover:bg-gray-800"
              disabled={!newTagName.trim()}
            >
              Submit
            </Button>
          </Space.Compact>
        </Form>
      </div>
      <div className="p-6 m-6 bg-white rounded">
        <Table columns={columns} dataSource={dataSource} loading={tagsLoading} />
      </div>
    </div>
  );
};
