import { IClients, IGetClientBySuperuserQueryResponse } from '@/types/User';
import { baseApi } from './base';

export const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSuperuserClients: builder.query<IClients, void>({
      query: () => ({ url: '/api/v2/superusers/clients' }),
      transformResponse: (response: IGetClientBySuperuserQueryResponse) => response.databases,
      providesTags: ['User'],
    }),
  }),
});

export const { useGetSuperuserClientsQuery } = usersApi;
